import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { DealerApiService } from 'src/app/services/dealer_service';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
import { CheckAccessRoles } from './../../../utils/check-access-roles';
declare var $: any;

@Component({
  selector: 'app-dealerlist',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './dealerlist.component.html',
  styleUrls: ['./dealerlist.component.css']
})
export class DealerlistComponent {

  fileName = ''
  selectedFile!: File;
  loading: boolean = false
  dealerList: any = []
  page: number = 0
  pageSize: number = PageSize.DEALER_LIST
  allTotal: number = 0
  downloadFileName: string = ''
  uplaodDealerCSVForm: FormGroup
  isUploadError: any;
  errorMessage: any;
  isUploading: boolean = false;
  isCreateAccess: boolean = false
  isInvoiceAccess: boolean = false
  isUploadAccess: boolean = false
  isUploadBrandSaleAccess: boolean = false
  isDownloadAccess: boolean = false
  searchName: any;
  nameList: any[] = [];

  constructor(
    private router: Router,
    private service: DealerApiService,
    private refresh: Refrsh,
    private store: StoreData,
    private fb: FormBuilder,
    private checkAccessRoles: CheckAccessRoles
  ) {
    this.uplaodDealerCSVForm = this.fb.group({
      type: ['', Validators.required],
      file: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.isCreateAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.DEALER_ADMIN])
    this.isInvoiceAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN])
    this.isUploadAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.DEALER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])
    this.isUploadBrandSaleAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])
    this.isDownloadAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.DEALER_ADMIN, UserLoginRole.ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])

    this.initData()
  }

  async initData() {
    try {
      await this.getAllDealerList();
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  async getAllDealerList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getAllDealerList(this.page, this.pageSize).subscribe({
        next: (response) => {
          this.dealerList = response.dealerInfoLst
          this.allTotal = response.allTotal;
          // if (this.isCreateAccess) {
          //   this.dealerList = response.dealerInfoLst
          //   this.allTotal = response.allTotal;
          // } else {
          //   this.dealerList = response.dealerInfoLst.filter((dealer: { enabled: boolean; }) => dealer.enabled !== false);
          //   this.allTotal = response.allTotal;
          // }
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          resolve()
        },
      })
    });
  }

  async getNameList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getNameList().subscribe({
        next: (response) => {
          this.nameList = response
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          resolve()
        },
      })
    });
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.nameList.filter((item: string[]) => item[1].toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  searchDealerByName() {
    this.loading = true

    var loginName: any
    if (Array.isArray(this.searchName)) {
      loginName = this.searchName[0]
    }

    this.service.getDealerInfoByName(loginName).subscribe({
      next: (response) => {
        this.dealerList = []
        this.allTotal = 0
        this.page = 0

        this.dealerList.push(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  clearSearchName() {
    this.refresh.refreshPage()
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  uploadFile() {
    var type = this.uplaodDealerCSVForm.get('type')?.value
    if (type === 'ALL') {
      if (confirm('Are you sure you want to upload the overwrite mode.This action cannot be undone.')) {
        this.uploadFileToServer(type)
      }
    } else if (type === 'ADD') {
      this.uploadFileToServer(type)
    } else if (type === 'AddBrandSale') {
      if (confirm('Are you sure you want to upload the overwrite mode.This action cannot be undone.')) {
        this.uploadAddBrandSaleSystemFileToServer()
      }
    }
  }

  uploadFileToServer(type: any) {
    this.isUploading = true
    if (this.selectedFile) {
      this.service.getUploadCSVDealer(type, this.selectedFile).subscribe({
        next: (response) => {
          alert("Success")
        },
        error: (e) => {
          this.isUploading = false
          if (e) {
            this.isUploadError = true
            this.errorMessage = e
          }
        },
        complete: () => {
          this.isUploading = false
          this.fileName = ''
          $('#uploadCSVModal').modal('hide');
          this.refresh.refreshPage()
        },
      })
    }
  }

  uploadAddBrandSaleSystemFileToServer() {
    this.isUploading = true
    if (this.selectedFile) {
      this.service.getUploadCSVDealerAddBrandSaleSystem(this.selectedFile).subscribe({
        next: (response) => {
          alert("Success")
        },
        error: (e) => {
          this.isUploading = false
          if (e) {
            this.isUploadError = true
            this.errorMessage = e

          }
        },
        complete: () => {
          this.isUploading = false
          this.fileName = ''
          $('#uploadCSVModal').modal('hide');
          this.refresh.refreshPage()
        },
      })
    }
  }

  dealerInfo(loginName: string) {
    this.router.navigate(['/nav/dealerInfo', loginName]);
  }

  createDealer() {
    this.router.navigate(['/nav/createDealer', "create"])
  }

  goToInvoice(name: any) {
    this.router.navigate(['/nav/invoice', name])
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllDealerList();
    }
  }

  downloadFile() {
    this.isUploading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.service.getDownloadFile(this.downloadFileName).subscribe({
      next: (value) => {
        this.downloadFileInLocal(value)
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }

  downloadFileInLocal(data: string) {
    const bom = '\uFEFF'; // Byte Order Mark for UTF-8
    const blob = new Blob([bom + data], { type: 'text/csv;charset=utf-8;' }); // Add BOM
    const download = document.createElement('a');
    download.href = URL.createObjectURL(blob);
    download.setAttribute('download', this.downloadFileName);
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    this.downloadFileName = ''
  }
}
